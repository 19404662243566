import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import { Layout } from '../layouts';

const BlackPage = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  height: 100%;
  color: ${({ theme }) => theme.colors.white};
  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;


const NotFoundPage = () => {
  return (
    <Layout>
      <BlackPage>
        <Link to="/">Go home</Link>
      </BlackPage>
    </Layout>
  )
}

export default NotFoundPage
